export const prebuildThemes = {
  custom: {},
  default: {
    primaryColor: '252 100% 67%',
    secondaryColor: '288 83.5% 52.5%',
    accentColor: '188 100% 63.5%',
    brandContentColor: '210 10% 96%',
    base100Color: '220 24% 8%',
    base200Color: '220 24% 13%',
    base300Color: '220 24% 15%',
    baseContentColor: '210 10% 96%',
    neutralColor: '210 10% 96',
    neutralContentColor: '0 0% 0%',
    navigationColor: '223 10% 52%',
    borderRadius: '1rem',
    headerFont: 'Outfit',
    bodyFont: 'Outfit',
  },
  'old-default': {
    primaryColor: '259 100% 54%',
    secondaryColor: '288 83.5% 52.5%',
    accentColor: '188 100% 63.5%',
    brandContentColor: '0 0% 87.8%',
    base100Color: '251 73% 9%',
    base200Color: '253 56% 13%',
    base300Color: '255 48% 17%',
    baseContentColor: '0 0% 87.8%',
    neutralColor: '0 0% 100%',
    neutralContentColor: '0 0% 0%',
    navigationColor: '0 0% 100%',
    borderRadius: '0.5rem',
    headerFont: 'Kanit',
    bodyFont: 'Karla',
    headerFontWeight: '600',
    bodyFontWeight: '400',
  },
  aqua: {
    primaryColor: '189 100% 62.9%',
    secondaryColor: '274 30.9% 56.9%',
    accentColor: '47 100% 80%',
    brandContentColor: '0 0% 100%',
    base100Color: '231 88.7% 38%',
    base200Color: '231 77.4% 32.9%',
    base300Color: '231 67.6% 29%',
    baseContentColor: '0 0% 100%',
    navigationColor: '0 0% 100%',
  },
  black: {
    primaryColor: '0 0% 100%',
    secondaryColor: '0 0% 100%',
    accentColor: '0 0% 100%',
    brandContentColor: '0 0% 0%',
    base100Color: '0 0% 0%',
    base200Color: '0 0% 20%',
    base300Color: '0 0% 30.2%',
    baseContentColor: '0 0% 100%',
    navigationColor: '0 0% 100%',
  },
  bumblebee: {
    primaryColor: '50 94.4% 58%',
    secondaryColor: '41 74.2% 52.9%',
    accentColor: '240 33.3% 14.1%',
    brandContentColor: '0 0% 100%',
    base100Color: '0 0% 100%',
    base200Color: '0 0% 96.1%',
    base300Color: '0 0% 89%',
    baseContentColor: '0 0% 0%',
    navigationColor: '0 0% 100%',
  },
  corporate: {
    primaryColor: '229 95.7% 63.9%',
    secondaryColor: '215 26.3% 59%',
    accentColor: '154 49% 60%',
    brandContentColor: '233 27.3% 12.',
    base100Color: '0 0% 100%',
    base200Color: '210 60% 98%',
    base300Color: '210 38.5% 94.9%',
    baseContentColor: '233 27.3% 12.9%',
    navigationColor: '233 27.3% 12.9%',
  },
  cupcake: {
    primaryColor: '183 47.4% 59%',
    secondaryColor: '338 71.4% 78%',
    accentColor: '39 84.1% 58%',
    brandContentColor: '0 0% 100%',
    base100Color: '24 33.3% 97.1%',
    base200Color: '27 22% 92%',
    base300Color: '22 14.3% 89%',
    baseContentColor: '280 46.5% 13.9%',
    navigationColor: '280 46.5% 13.9%',
  },
  cyberpunk: {
    primaryColor: '345 100% 72.9%',
    secondaryColor: '195 80.4% 70%',
    accentColor: '276 74.3% 71%',
    brandContentColor: '0 0% 0%',
    base100Color: '56 100% 50%',
    base200Color: '56 100% 42.9%',
    base300Color: '56 100% 36.1%',
    baseContentColor: '0 0% 0%',
    navigationColor: '0 0% 0%',
  },
  cyanOne: {
    primaryColor: '231 98% 33%',
    secondaryColor: '231 98% 33%',
    accentColor: '276 74.3% 71%',
    brandContentColor: '0 0% 100%',
    base100Color: '180 43% 86%',
    base200Color: '183 58% 86%',
    base300Color: '180 43% 90%',
    baseContentColor: '231 98% 33%',
    borderRadius: '0 rem',
    headerFont: 'Fahkwang',
    bodyFont: 'Fahkwang',
    headerFontWeight: '700',
    bodyFontWeight: '400',
  },
  dark: {
    primaryColor: '259 100% 54%',
    secondaryColor: '288 83.5% 52.5%',
    accentColor: '188 100% 63.5%',
    brandContentColor: '210 10% 96%',
    base100Color: '240 3% 6%',
    base200Color: '240 3% 8%',
    base300Color: '240 3% 10%',
    baseContentColor: '210 10% 96%',
    neutralColor: '210 10% 96',
    neutralContentColor: '0 0% 0%',
    navigationColor: '223 10% 52%',
    borderRadius: '0.6rem',
    headerFont: 'Outfit',
    bodyFont: 'Outfit',
  },
  dracula: {
    primaryColor: '330 100% 85.1%',
    secondaryColor: '115 100% 85.1%',
    accentColor: '60 100% 85.1%',
    brandContentColor: '233 12.1% 12.9%',
    base100Color: '244 13% 21.2%',
    base200Color: '245 14.3% 15.1%',
    base300Color: '233 12.1% 12.9%',
    baseContentColor: '251 100% 90%',
    navigationColor: '251 100% 90%',
  },
  emerald: {
    primaryColor: '141 50% 60%',
    secondaryColor: '219 96.1% 60%',
    accentColor: '60 100% 85.1%',
    brandContentColor: '210 20% 98%',
    base100Color: '0 0% 100%',
    base200Color: '210 20% 98%',
    base300Color: '210 20% 94%',
    baseContentColor: '219 20.3% 25.1%',
    navigationColor: '219 20.3% 25.1%',
  },
  fantasy: {
    primaryColor: '296 82.8% 25.1%',
    secondaryColor: '200 100% 37.1%',
    accentColor: '31 94.4% 51.2%',
    brandContentColor: '0 0% 100%',
    base100Color: '0 0% 100%',
    base200Color: '210 20% 98%',
    base300Color: '216 12.2% 83.9%',
    baseContentColor: '215 27.9% 16.9%',
    navigationColor: '215 27.9% 16.9%',
  },
  forest: {
    primaryColor: '141 72% 42%',
    secondaryColor: '141 74.7% 48%',
    accentColor: '35 69% 52%',
    brandContentColor: '0 0% 100%',
    base100Color: '0 12.2% 8%',
    base200Color: '0 9.7% 6.1%',
    base300Color: '0 20% 2%',
    baseContentColor: '0 0% 100%',
    navigationColor: '0 0% 100%',
  },
  garden: {
    primaryColor: '139 16% 42.9%',
    secondaryColor: '97 37.1% 93.1%',
    accentColor: '0 67.7% 93.9%',
    brandContentColor: '0 0% 100%',
    base100Color: '0 4.3% 91%',
    base200Color: '0 5.2% 81%',
    base300Color: '0 5.4% 71%',
    baseContentColor: '0 3.2% 6.1%',
    navigationColor: '0 3.2% 6.1%',
  },
  halloween: {
    primaryColor: '32 89.3% 52.2%',
    secondaryColor: '271 45.8% 42%',
    accentColor: '91 100% 32.9%',
    brandContentColor: '0 0% 100%',
    base100Color: '0 0% 12.9%',
    base200Color: '180 3.6% 11%',
    base300Color: '180 7.3% 8%',
    baseContentColor: '0 0% 100%',
    navigationColor: '0 0% 100%',
  },
  light: {
    primaryColor: '259 100% 54%',
    secondaryColor: '288 83.5% 52.5%',
    accentColor: '188 100% 63.5%',
    brandContentColor: '60 33% 98%',
    base100Color: '0 0% 100%',
    base200Color: '0 0% 100%',
    base300Color: '0 0% 94%',
    baseContentColor: '224 14% 15',
    neutralColor: '0 0% 0%',
    neutralContentColor: '0 0% 100%',
    navigationColor: '0 0% 100%',
    borderRadius: '0.7rem',
    headerFont: 'Outfit',
    bodyFont: 'Outfit',
  },
  lofi: {
    primaryColor: '0 0% 50.2%',
    secondaryColor: '0 0% 30.2%',
    accentColor: '0 0% 10.2%',
    brandContentColor: '240 4% 95.1%',
    base100Color: '0 0% 100%',
    base200Color: '0 0% 100%',
    base300Color: '0 0% 100%',
    baseContentColor: '0 0% 49%',
    navigationColor: '0 0% 49%',
  },
  luxury: {
    primaryColor: '319 21.8% 26.1%',
    secondaryColor: '218 54.3% 18%',
    accentColor: '319 21.8% 26.1%',
    brandContentColor: '37 67.3% 58%',
    base100Color: '240 10% 3.9%',
    base200Color: '270 4.3% 9%',
    base300Color: '270 2.2% 18%',
    baseContentColor: '0 0% 100%',
    navigationColor: '0 0% 100%',
    borderRadius: '0.00rem',
  },
  'luxury-light': {
    primaryColor: '0 0% 0%',
    secondaryColor: '218 54.3% 18%',
    accentColor: '319 21.8% 26.1%',
    brandContentColor: '37 67.3% 58%',
    base100Color: '0 0% 100%',
    base200Color: '240 23.8% 95.9%',
    base300Color: '240 1.8% 88.8%',
    baseContentColor: '0 0% 0%',
    navigationColor: '0 0% 0%',
    borderRadius: '0.00rem',
  },
  pastel: {
    primaryColor: '158 54.6% 81%',
    secondaryColor: '352 70.5% 88%',
    accentColor: '158 54.6% 81%',
    brandContentColor: '0 0% 100%',
    base100Color: '0 0% 100%',
    base200Color: '210 20% 98%',
    base300Color: '216 12.2% 83.9%',
    baseContentColor: '206 3.1% 55.1%',
    navigationColor: '206 3.1% 55.1%',
  },
  retro: {
    fontFamily: 'uisecondaryerif Georgia Cambria Times New Roman Times serif',
    primaryColor: '3 73.8% 76.1%',
    secondaryColor: '145 27.3% 72%',
    accentColor: '49 67.2% 76.1%',
    brandContentColor: '345 5.3% 14.9%',
    base100Color: '45 47.1% 80%',
    base200Color: '45 37.1% 72%',
    base300Color: '42 36% 65.1%',
    baseContentColor: '345 5.3% 14.9%',
    navigationColor: '345 5.3% 14.9%',
  },
  synthwave: {
    primaryColor: '321 69.6% 69%',
    secondaryColor: '197 87.4% 75.1%',
    accentColor: '48 89% 57.1%',
    brandContentColor: '257 63.2% 17.1%',
    base100Color: '254 59.1% 25.9%',
    base200Color: '253 60.8% 19%',
    base300Color: '257 64.3% 11%',
    baseContentColor: '260 60% 98%',
    navigationColor: '260 60% 98%',
  },
  valentine: {
    primaryColor: '353 73.8% 67.1%',
    secondaryColor: '254 86.4% 76.9%',
    accentColor: '181 55.6% 70%',
    brandContentColor: '0 0% 100%',
    base100Color: '318 46.4% 89%',
    base200Color: '315 58.9% 79%',
    base300Color: '316 62.3% 72.9%',
    baseContentColor: '344 38.5% 28%',
    navigationColor: '344 38.5% 28%',
  },
  wireframe: {
    primaryColor: '0 0% 72.2%',
    secondaryColor: '0 0% 72.2%',
    accentColor: '0 0% 72.2%',
    brandContentColor: '0 0% 0%',
    base100Color: '0 0% 100%',
    base200Color: '0 0% 100%',
    base300Color: '0 0% 100%',
    baseContentColor: '0 0% 0%',
    navigationColor: '0 0% 0%',
  },
};
